import { GridDataRowDTO } from "domain/types"
import { Tagged } from "type-fest"

export enum ColumnFieldType {
    VALUE = "value",
    NAME = "name",
}

export class ColumnField {
    identifier: DataColumnIdentifier // e.g. "channel"
    fieldType: ColumnFieldType // e.g. ColumnFieldType.VALUE

    constructor(identifier: DataColumnIdentifier, fieldType: ColumnFieldType) {
        this.identifier = identifier
        this.fieldType = fieldType
    }

    toString(): string {
        return this.identifier + "." + this.fieldType
    }

    /**
     * "channel.value" -> ColumnField(identifier = DataColumnIdentifier("channel"), fieldType = DimensionFieldType.VALUE)
     */
    static recognize = (text: string): ColumnField => {
        const tokens = text.split(".")
        if (tokens.length !== 2) {
            throw new Error("Invalid column field format: " + text)
        }

        return new ColumnField(asDataColumnIdentifier(tokens[0]), ColumnFieldType[tokens[1]?.toUpperCase()])
    }

    static valueField = (identifier: DataColumnIdentifier): ColumnField =>
        new ColumnField(identifier, ColumnFieldType.VALUE)

    static nameField = (identifier: DataColumnIdentifier): ColumnField =>
        new ColumnField(identifier, ColumnFieldType.NAME)
}

export type DataColumnIdentifier = Tagged<string, "DataColumnIdentifier">

export const asDataColumnIdentifier = (value: string): DataColumnIdentifier => {
    if (value.indexOf(".") !== -1) {
        throw new Error("DataColumnIdentifier value cannot contain '.'")
    }
    return value as DataColumnIdentifier
}

const DimensionService = {
    /**
     * e.g "channel" -> "channel.value"
     * @param identifier
     * @deprecated Use {@link ColumnField.valueField} instead
     */
    getValueColumn: (identifier: DataColumnIdentifier): string =>
        DimensionService.getValueColumnField(identifier).toString(),

    /**
     * @param identifier
     * @deprecated Use {@link ColumnField.valueField} instead
     */
    getValueColumnField: (identifier: DataColumnIdentifier): ColumnField =>
        new ColumnField(identifier, ColumnFieldType.VALUE),

    /**
     * e.g "channel" -> "channel.name"
     * @param identifier
     * @deprecated Use {@link ColumnField.nameField} instead
     */
    getNameColumn: (identifier: DataColumnIdentifier): string =>
        new ColumnField(identifier, ColumnFieldType.NAME).toString(),

    /**
     * Extracts name data of the dimensionIdentifier. If not found then value data.
     *
     * @param row
     * @param columnIdentifier
     */
    getColumnFieldNameOrValue: (row: GridDataRowDTO, columnIdentifier: DataColumnIdentifier): any => {
        const columnData = row[columnIdentifier]
        if (!columnData) {
            return null
        }

        return columnData[ColumnFieldType.NAME] ? columnData[ColumnFieldType.NAME] : columnData[ColumnFieldType.VALUE]
    },
}

export default DimensionService
