import React, { useState } from "react"
import { IconButton, useMediaQuery, useTheme } from "@mui/material"
import Popover from "@mui/material/Popover"
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded"
import { TOP_BAR_HEIGHT } from "layout/MainLayout/constants"

type Props = {
    text: string
}

const ResponsiveInfoText: React.FC<Props> = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            {isLargeScreen ? (
                <div dangerouslySetInnerHTML={{ __html: props.text }} />
            ) : (
                <>
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            position: "absolute",
                            right: theme.spacing(1),
                            top: `calc(${TOP_BAR_HEIGHT} + 5px)`,
                        }}
                    >
                        <QuestionMarkRoundedIcon sx={{ height: "16px", width: "16px" }} /> Learn more
                    </IconButton>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <div style={{ padding: theme.spacing(2) }} dangerouslySetInnerHTML={{ __html: props.text }} />
                    </Popover>
                </>
            )}
        </>
    )
}

export default ResponsiveInfoText
