import { QuerySettingsDTO } from "generated/models"
import { postData } from "shared/service/api"
import store from "shared/redux/store"
import { CancelTokenSource } from "axios"
import UrlService from "shared/service/url.service"
import { LoadResponseDTOReportingDataSetDTO } from "generated/models"
import UrlUtil from "shared/util/UrlUtil"

/**
 * Loads dashboards loadData api
 *
 * @param querySettingsDTO
 * @param cancelTokenSource
 */
const loadData = (
    querySettingsDTO: QuerySettingsDTO,
    cancelTokenSource?: CancelTokenSource,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const settings = querySettingsDTO.appContext
        ? querySettingsDTO
        : { ...querySettingsDTO, appContext: store.getState().appContext.appContext }

    return postData<LoadResponseDTOReportingDataSetDTO>(
        UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/analytics"),
        "/loadData",
        settings,
        {
            cancelToken: cancelTokenSource?.token,
        },
    )
}

const DashboardsLoadDataService = {
    loadData: loadData,
}

export default DashboardsLoadDataService
