import { Channel } from "Constants"
import { FormActionDTO, UpdateResponseDTO } from "generated/models"
import { FormFieldConfigDTO } from "generated/models"
import {
    ConditionClauseDTO,
    ContainerElementDTO,
    FormConfigDTO,
    LayoutElementDTO,
    NumberValueClauseDTO,
    SelectFormElementDTO,
} from "generated/models"
import { produce } from "immer"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { FormikProps, useFormik } from "formik"
import FormUtil from "shared/util/FormUtil"
import LayoutUtil from "shared/util/LayoutUtil"
import ConditionClauseService from "shared/service/conditionClauseService"
import { log } from "shared/util/log"
import DimensionService, {
    asDataColumnIdentifier,
    ColumnField,
    ColumnFieldType,
} from "domain/dimension/service/DimensionService"
import store, { RootState } from "shared/redux/store"
import { modalClose, modalLoadingState, modalStopSubmitting } from "shared/component/modals/redux/modals.slice"
import { useSelector } from "react-redux"
import FormService from "shared/service/form.service"
import UrlService from "shared/service/url.service"
import PasswordService from "domain/password/service/PasswordService"
import PayloadUtil from "shared/util/PayloadUtil"
import { ElementSetting, useRootElementContext } from "shared/component/layout/context/RootElementContext"
import { ValidationState } from "shared/component/forms/ValidationState"
import {
    ActionPopupConfig,
    DataRowDTO,
    FieldKeyValue,
    FormCustomListener,
    FormElementDTO,
    GridDataRowDTO,
    LayoutElementType,
    ModalConfig,
    SelectFormElement,
    UIFormConfig,
} from "domain/types"

type Values = Record<string, Record<string, any>>
export type FormContextProperties = {
    uiFormConfig: UIFormConfig
    popupConfig: ActionPopupConfig
    formik: FormikProps<Values>
    setFormElementValueAndUpdateContextSettingsForInitialValues?: (element: FormElementDTO) => Promise<void>
    setReadOnlyElements?: React.Dispatch<React.SetStateAction<Set<string>>>
    onChange?: (value: any, field: string) => void
    resetField?: (field: any) => void
    customFormFieldListeners?: { [key: string]: (string) => void }
    loadingFieldStates: { [key: string]: boolean }
    enableValidation: (dimensionIdentifier: string) => void
    disableValidation: (dimensionIdentifier: string) => void
}

const FormContext = createContext<FormContextProperties>(undefined)

export const useFormContext = (strict = true): FormContextProperties => {
    const context = useContext(FormContext)

    if (!context && strict) {
        throw new Error("Missing FormContext in its parent.")
    }

    return context
}

export type FormContextProviderProps = {
    uiFormConfig: UIFormConfig
    popupConfig: ActionPopupConfig
    children: React.ReactNode
}

const FILTER_PATH = "/loaddimensionvalues"

export const FormContextProvider: React.FC<FormContextProviderProps> = (
    props: FormContextProviderProps,
): JSX.Element => {
    const { updateElementSettings } = useRootElementContext()

    const modals = useSelector((state: RootState) => state.modals)
    const modal = (modals as ModalConfig[])?.find((modal) => modal.identifier == props.popupConfig.identifier)

    const [uiFormConfig, setUiFormConfig] = useState<UIFormConfig>(props.uiFormConfig)
    const [readOnlyElements, setReadOnlyElements] = useState<Set<string>>(new Set())
    const [userSetValues, setUserSetValues] = useState<FieldKeyValue[]>([])
    // contains a map of form field identifiers and their loading state (true/false)
    const [loadingFieldStates, setLoadingFieldStates] = useState<{ [key: string]: boolean }>({})

    const { formConfig } = uiFormConfig

    const isEditMode = FormUtil.getIsEditMode(formConfig)
    const isCreateMode = FormUtil.getIsCreateMode(formConfig)

    const formFieldConfigs = collectFormFieldConfigs(formConfig)
    const [validationState, setValidationState] = useState(ValidationState.parseValidationSchema(formFieldConfigs))
    const initialValues = useMemo(
        () => collectInitialValues(formConfig.layoutConfig, uiFormConfig.itemData),
        [formConfig.layoutConfig, uiFormConfig.itemData],
    )

    const formik = useFormik({
        validationSchema: () => validationState.toYupSchema(),
        initialValues: initialValues,
        onSubmit: (values) => {
            log.debug("-- FORM: Submit button clicked with values", values)
            return handleSubmit(
                flattenValues(values),
                () => store.dispatch(modalLoadingState({ identifier: modal.identifier, loading: true })),
                props.popupConfig.onAfterSubmit,
            ).then((validationAndSubmitSuccessful: boolean) => {
                if (validationAndSubmitSuccessful) {
                    if (!props.popupConfig.keepOpenAfterCreateAndEdit) {
                        // if there is nothing else to do: simply close the modal
                        store.dispatch(modalClose({ identifier: modal.identifier }))
                    }
                } else {
                    // validation (in frontend or backend) was not successful
                    store.dispatch(modalLoadingState({ identifier: modal.identifier, loading: false }))
                }
            })
        },
    })

    /**
     * Monitors modals store changes and if the corresponding modal gets submitting=true flag
     * then execute handleSubmit
     */
    useEffect(() => {
        if (modal?.submitting) {
            store.dispatch(modalStopSubmitting({ identifier: modal.identifier }))
            formik.submitForm().catch((error) => log.debug("Error while submitting form", error))
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal?.submitting])

    useEffect(() => {
        const selectElements = getAllSelectElements()

        if (uiFormConfig.itemData && uiFormConfig.itemData.length > 0) {
            // EDIT form
            // const newInitialValues = FormUtil.getInitialValues(uiFormConfig.itemData, uiFormConfig.formConfig.layoutConfig)
            // setInitialValues(newInitialValues)

            // Set values for all form elements and updates context settings
            LayoutUtil.findFormElements(uiFormConfig.formConfig.layoutConfig).forEach((element) => {
                // initially each form field has a non-loading state
                setElementLoadingState(element.formFieldConfig.dimensionIdentifier, false)

                // if the element is a FormSelectElement then load the select entries
                loadSelectElementAndUpdateUiFormConfig(element.formFieldConfig.dimensionIdentifier, selectElements)
            })
        } else {
            // CREATE form
            loadAllSelectElementsAndUpdateUiFormConfig(selectElements)
        }
        // TODO: is it safe to add the missing dependencies? We probably want to run this only once?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Finds all {@link SelectFormElementDTO}s with no preventInitialLoading=true
     */
    const getAllSelectElements = (): SelectFormElementDTO[] => {
        return LayoutUtil.findSelectElements(uiFormConfig.formConfig.layoutConfig).filter(
            (el) => !el.selectConfig.preventInitialLoading,
        )
    }

    /**
     * Loads form select element entries for [formElementIdentifier] and updates the global ui form config
     * @param formElementIdentifier
     * @param allSelectElements
     */
    const loadSelectElementAndUpdateUiFormConfig = async (
        formElementIdentifier: string,
        allSelectElements: SelectFormElementDTO[],
    ) => {
        const currentSelectElement = allSelectElements.find(
            (element) => element.formFieldConfig.dimensionIdentifier === formElementIdentifier,
        )

        if (currentSelectElement) {
            if (!currentSelectElement.selectConfig.preventInitialLoading) {
                // Find all allSelectElements in the form that [currentElement] depends on.
                const currentElementDependencies = allSelectElements.filter(
                    (selectElement) =>
                        currentSelectElement.selectConfig?.dependsOn.some(
                            (dep) => selectElement.formFieldConfig.dimensionIdentifier === dep.filterIdentifier,
                        ),
                )

                const filterClauses = currentElementDependencies
                    // filter all [currentElementDependencies] that have a value set.
                    .filter((selectElement) => uiFormConfig.itemData.some((row) => row[selectElement.identifier]))
                    .map((selectElement) => {
                        return {
                            clauseType: "Number",
                            columnName: selectElement.formFieldConfig.dimensionIdentifier,
                            type: "EQUALS",
                            value: uiFormConfig.itemData.find((row) => row[selectElement.identifier])[
                                selectElement.identifier
                            ]?.value,
                        } as NumberValueClauseDTO
                    })

                const combineFilterQueries = ConditionClauseService.combineFilterQueries([
                    uiFormConfig?.filter,
                    ...filterClauses,
                ])

                setElementLoadingState(currentSelectElement.formFieldConfig.dimensionIdentifier, true)
                // loads entries for currentSelectElement and updates its (element.selectConfig.selectEntries)
                const currentSelectElementWithEntries: SelectFormElement[] = await LayoutUtil.loadSelectElements(
                    [currentSelectElement],
                    FILTER_PATH,
                    combineFilterQueries,
                    null,
                )
                setElementLoadingState(currentSelectElementWithEntries[0].formFieldConfig.dimensionIdentifier, false)

                return setUiFormConfig((prev) => {
                    const newLayoutConfig = LayoutUtil.replaceElementByIdentifier(
                        prev.formConfig.layoutConfig,
                        currentSelectElementWithEntries[0] as FormElementDTO,
                    )

                    return {
                        ...prev,
                        formConfig: {
                            ...prev.formConfig,
                            layoutConfig: newLayoutConfig,
                        },
                    }
                })
            }
        }
    }

    /**
     * Loads all {@link FormSelectElement}s from {@link uiFormConfig} and updates the global ui form config
     */
    const loadAllSelectElementsAndUpdateUiFormConfig = async (selectElements: SelectFormElementDTO[]) => {
        selectElements.forEach((currentElement) => {
            loadSelectElementAndUpdateUiFormConfig(
                currentElement.formFieldConfig.dimensionIdentifier,
                selectElements,
            ).then(() => log.debug(`Loading of ${currentElement.formFieldConfig.dimensionIdentifier} is done`))
        })
    }

    /**
     * Validates the form data and the if the data is valid, sends it to the backend.
     * Returns asynchronously the Promise<boolean>, whether the validation AND submit were successful.
     *
     * @param values
     * @param onFrontendValidationSuccessCallback - callback, that will be invoked after the form data are validated
     * @param onAfterSubmit - callback, that will be invoked after the form is successfully submitted
     */
    const handleSubmit = async (
        values: DataRowDTO,
        onFrontendValidationSuccessCallback: () => void,
        onAfterSubmit?: () => void,
    ): Promise<boolean> => {
        log.debug("-- FORM: submit()")
        log.debug("-- FORM: uiFormConfig: ", uiFormConfig)

        // validate in frontend first, submit to backend only if frontend validation is successful
        return Promise.resolve() // TODO
            .then(() => {
                // e.g. set modal contentLoading: true
                onFrontendValidationSuccessCallback()

                const { actions } = uiFormConfig.formConfig
                const payload = createFormSubmitPayload(transformValuesBeforeSubmit(values), actions)
                log.debug("-- FORM: rows to update: ", payload)

                const { baseApi } = uiFormConfig
                return FormService.submitForm(
                    payload,
                    `${baseApi}/${actions.submit.url}`,
                    actions.submit.method,
                    UrlService.getGatewayUrl(),
                ).then((result) => {
                    if (result.response.success) {
                        log.debug("Response was successful")

                        if (onAfterSubmit && typeof onAfterSubmit === "function") {
                            // e.g. reload the gird
                            onAfterSubmit()
                        }

                        if (result.updatedData) {
                            if (
                                props.popupConfig.onSubmitSuccess &&
                                typeof props.popupConfig.onSubmitSuccess === "function"
                            ) {
                                // e.g. ActionService.editLastCreatedOrUpdatedItem
                                props.popupConfig.onSubmitSuccess(result.updatedData)
                            }
                        }
                        if (!uiFormConfig.formConfig.keepOpenAfterCreateAndEdit) {
                            // we know that form is going to be closed after successful update, so let's reset it now (there would probably be a better place to do this? before close?)
                            formik.resetForm()
                        }
                    } else {
                        log.debug("Response was not successful")
                        showValidationErrors(result)
                    }

                    return result.response.success
                })
            })
            .catch((errors) => {
                log.error("form validation errors: ", errors)
                return false
            })
    }

    /**
     * Transforms values before submit. E.g. hashes password values.
     * @param values
     */
    const transformValuesBeforeSubmit = (values: DataRowDTO): DataRowDTO => {
        const updatedValues: DataRowDTO = {}
        Object.keys(values).forEach((key) => {
            const formElement = LayoutUtil.findFormElementByIdentifier(uiFormConfig.formConfig.layoutConfig, key)

            if (formElement.elementType === LayoutElementType.FORM_ELEMENT_INPUT_PASSWORD) {
                updatedValues[key] = PasswordService.hash(values[key])
            } else {
                updatedValues[key] = values[key]
            }
        })

        log.debug("-- FORM: updatedValues", updatedValues)
        return updatedValues
    }

    /**
     * Creates from the form [values] payload, that will be submitted to the backend
     *
     * @param values
     * @param actions
     */
    const createFormSubmitPayload = (values: DataRowDTO, actions?: FormActionDTO): DataRowDTO[] => {
        const requiredDimensions = actions?.submit?.requiredDimensions ?? []
        const requiredAdditionalData = extractDimensionValuesFromFilter(
            Array.from(requiredDimensions),
            props.popupConfig?.additionalFilters,
        )

        const valuesCopy = { ...values }

        // Set all readOnly field values to undefined because undefined entries will not be sent to the backend and that is what we want.
        for (const key in valuesCopy) {
            if (readOnlyElements.has(key)) {
                valuesCopy[key] = undefined
            }
        }

        // only send form values as payload when creating a new entry
        const payload = isCreateMode ? [{ ...valuesCopy, ...requiredAdditionalData }] : []

        // map form values to item ids in edit mode
        const column = uiFormConfig.formConfig.mainDimension
        if (isEditMode) {
            // mainDimension id array
            const mainDimensionIds = uiFormConfig.itemData.map((row) => row[column.identifier].value)

            PayloadUtil.createEditPayload(
                DimensionService.getValueColumn(asDataColumnIdentifier(column.identifier)),
                mainDimensionIds,
                valuesCopy,
            ).forEach((row) => payload.push(row))
        }

        return payload
    }

    /**
     * Show each validation error in the form
     *
     * @param updateResponseDTO
     */
    const showValidationErrors = (updateResponseDTO: UpdateResponseDTO) => {
        for (const key in updateResponseDTO.validationErrors) {
            formik.setFieldError(key, updateResponseDTO.validationErrors[key].join(", "))
        }
    }

    /**
     * Executes setFormElementValueAndUpdateContextSettings for initialValues from the state
     *
     * @param element
     */
    const setFormElementValueAndUpdateContextSettingsForInitialValues = async (
        element: FormElementDTO,
    ): Promise<void> => {
        return await setFormElementValueAndUpdateContextSettings(element, initialValues)
    }

    /**
     * Sets form element value and updates context settings if the element has useAsSetting=true flag
     *
     * @param element
     * @param allInitialValues
     */
    const setFormElementValueAndUpdateContextSettings = async (
        element: FormElementDTO,
        allInitialValues: Values = initialValues,
    ): Promise<void> => {
        let fieldKeyValue = findFieldValue(element, allInitialValues)

        const dimensionIdentifier = element.formFieldConfig.dimensionIdentifier

        // if no field value in initial values found, then set default value
        if (
            (fieldKeyValue?.value === undefined || fieldKeyValue?.value === null) &&
            element.formFieldConfig?.defaultValue !== undefined &&
            !userSetValues.some((el) => el.name == dimensionIdentifier)
        ) {
            fieldKeyValue = { name: dimensionIdentifier, value: element.formFieldConfig.defaultValue }
        }

        if (fieldKeyValue) {
            await formik.setFieldValue(fieldKeyValue.name, fieldKeyValue.value)

            if (element.useAsSetting) {
                updateElementSettings({ key: dimensionIdentifier, value: fieldKeyValue.value } as ElementSetting)
            }
        }
    }

    /**
     * Finds field value for form element from the allInitialValues list
     *
     * @param element
     * @param allInitialValues
     */
    const findFieldValue = (element: FormElementDTO, allInitialValues: Values): FieldKeyValue => {
        const [dimension, nameOrValue] = element.formFieldConfig.dimensionIdentifier.split(".")
        return { name: element.formFieldConfig.dimensionIdentifier, value: allInitialValues[dimension][nameOrValue] }
    }

    /**
     * Implementation for custom listeners
     */
    const customFormFieldListeners = {
        [FormCustomListener.SUB_CAMPAIGN_CREATE_FORM_ON_CHANNEL_CHANGE]: async (channelDropdown) => {
            await formik.setFieldValue(
                DimensionService.getNameColumn(asDataColumnIdentifier("sub_campaign")),
                channelDropdown.textValue,
            )

            const createSameNamedLineItemFieldIdentifier = DimensionService.getValueColumn(
                asDataColumnIdentifier("sc_create_same_named_line_item"),
            )
            if ([Channel.SEO, Channel.DIRECT_REFERRED, Channel.DIRECT_TYPEIN].includes(channelDropdown.value)) {
                await formik.setFieldValue(createSameNamedLineItemFieldIdentifier, true)
                setReadOnlyElements(
                    produce((draft) => {
                        draft.add(createSameNamedLineItemFieldIdentifier)
                    }),
                )
                await onChange(true, createSameNamedLineItemFieldIdentifier).catch()
            } else {
                !userSetValues.some((el) => el.name == createSameNamedLineItemFieldIdentifier) &&
                    (await resetField(createSameNamedLineItemFieldIdentifier))
                setReadOnlyElements(
                    produce((draft) => {
                        draft.delete(createSameNamedLineItemFieldIdentifier)
                    }),
                )
                setUserSetValues((prev) => prev.filter((el) => el.name != createSameNamedLineItemFieldIdentifier))
            }
        },
    }

    const onChange = async (value: any, field: string): Promise<void> => {
        setUserSetValues((pref) => [...pref.filter((el) => el.name != field), { name: field, value } as FieldKeyValue])
        if (value === undefined || value === "") {
            await formik.setFieldValue(field, "")
        }

        await reloadAllDependantSelectElements(value, field)
    }

    const reloadAllDependantSelectElements = async (value: any, field: string) => {
        const dependentSelectElements = LayoutUtil.findSelectElements(uiFormConfig?.formConfig?.layoutConfig).filter(
            (selectFormElementDTO) =>
                selectFormElementDTO?.selectConfig?.dependsOn?.find((dep) => dep.filterIdentifier === field) !==
                undefined,
        )

        if (dependentSelectElements.length > 0) {
            const combineFilterQueries = value?.value
                ? ConditionClauseService.combineFilterQueries([
                      uiFormConfig?.filter,
                      {
                          clauseType: "Number",
                          columnName: field,
                          type: "EQUALS",
                          value: value?.value,
                      } as NumberValueClauseDTO,
                  ])
                : uiFormConfig?.filter
            setElementLoadingStateForList(dependentSelectElements, true)
            const dependentSelectElementsWithEntries = await LayoutUtil.loadSelectElements(
                dependentSelectElements,
                FILTER_PATH,
                combineFilterQueries,
                null,
            )
            setElementLoadingStateForList(dependentSelectElementsWithEntries, false)

            let newLayoutConfig = uiFormConfig.formConfig.layoutConfig
            for (const element of dependentSelectElementsWithEntries) {
                await formik.setFieldValue(element.formFieldConfig.dimensionIdentifier, null)
                newLayoutConfig = LayoutUtil.replaceElementByIdentifier(
                    uiFormConfig.formConfig.layoutConfig,
                    element as FormElementDTO,
                )
            }

            setUiFormConfig(
                produce((draft) => {
                    draft.formConfig.layoutConfig = newLayoutConfig
                }),
            )
        }
    }

    /**
     * Updates the loading state for all [selectElements]
     *
     * @param selectElements
     * @param isLoading
     */
    const setElementLoadingStateForList = (selectElements: SelectFormElementDTO[], isLoading: boolean) => {
        selectElements.forEach((element) =>
            setElementLoadingState(element.formFieldConfig.dimensionIdentifier, isLoading),
        )
    }

    /**
     * Updates the loading state for element with identifier [elementIdentifier]
     * @param elementIdentifier
     * @param isLoading
     */
    const setElementLoadingState = (elementIdentifier: string, isLoading: boolean) => {
        setLoadingFieldStates((prev) => {
            prev[elementIdentifier] = isLoading

            return prev
        })
    }

    const resetField = async (field: string) => {
        await formik.setFieldValue(field, initialValues[field])
    }

    const enableValidation = (dimensionIdentifier: string) => {
        setValidationState((currentValidationState) => currentValidationState.enableRule(dimensionIdentifier))
    }

    const disableValidation = (dimensionIdentifier: string) => {
        setValidationState((currentValidationState) => currentValidationState.disableRule(dimensionIdentifier))
    }

    const context: FormContextProperties = {
        uiFormConfig,
        popupConfig: props.popupConfig,
        formik,
        setFormElementValueAndUpdateContextSettingsForInitialValues,
        setReadOnlyElements,
        onChange,
        customFormFieldListeners,
        resetField,
        loadingFieldStates,
        enableValidation: enableValidation,
        disableValidation: disableValidation,
    }

    return <FormContext.Provider value={context}>{props.children}</FormContext.Provider>
}

const collectFormFieldConfigs = (formConfig: FormConfigDTO): FormFieldConfigDTO[] => {
    const formFieldConfigs: FormFieldConfigDTO[] = []

    const collectFormFieldConfigsRecursive = (layoutElement: LayoutElementDTO) => {
        if (LayoutUtil.isFormElement(layoutElement)) {
            formFieldConfigs.push(layoutElement.formFieldConfig)
        }
        if (isContainerElement(layoutElement)) {
            layoutElement.children.forEach((child) => collectFormFieldConfigsRecursive(child))
        }
    }

    collectFormFieldConfigsRecursive(formConfig.layoutConfig)
    return formFieldConfigs
}

export const isContainerElement = (layoutElement: LayoutElementDTO): layoutElement is ContainerElementDTO => {
    return [
        LayoutElementType.CONTAINER,
        LayoutElementType.PANEL,
        LayoutElementType.TAB,
        LayoutElementType.CONVERSION_LIST_ELEMENT,
        LayoutElementType.CONVERSION_LIST_CUSTOMER_JOURNEY_ELEMENT,
    ]
        .map((e) => e.toString())
        .includes(layoutElement.elementType)
}

const collectInitialValues = (layoutConfig: ContainerElementDTO, itemData: GridDataRowDTO[]): Values => {
    const initialValues: Values = {}

    const formElements = LayoutUtil.findFormElements(layoutConfig)

    formElements.forEach((formElement) => {
        const dimensionField = ColumnField.recognize(formElement.formFieldConfig.dimensionIdentifier)
        const columnResponseDTO = FormUtil.getFieldValue(itemData, dimensionField)

        let value
        if (dimensionField.fieldType == ColumnFieldType.VALUE && columnResponseDTO?.value !== undefined) {
            value = columnResponseDTO.value
        } else if (dimensionField.fieldType == ColumnFieldType.NAME && columnResponseDTO?.name !== undefined) {
            value = columnResponseDTO.name
        } else if (formElement.formFieldConfig.defaultValue !== undefined) {
            value = formElement.formFieldConfig.defaultValue
        } else {
            const isSelectFormElement =
                formElements.find(
                    (element: FormElementDTO) =>
                        element.formFieldConfig.dimensionIdentifier === formElement.formFieldConfig.dimensionIdentifier,
                ).elementType === LayoutElementType.FORM_ELEMENT_SELECT
            value = isSelectFormElement ? null : ""
        }

        initialValues[dimensionField.identifier] = { [dimensionField.fieldType]: value }
    })

    log.debug("FORM: Collected initialValues", initialValues)
    return initialValues
}

/**
 * Extracts filter values for required dimensions. Returns a map with keys as required dimensions and values as dimension value
 *
 * @param requiredDimensions
 * @param additionalFilters
 */
const extractDimensionValuesFromFilter = (
    requiredDimensions: string[],
    additionalFilters?: ConditionClauseDTO,
): { [key: string]: any } => {
    return requiredDimensions.reduce((acc, requiredDimension) => {
        const dimensionValues: any[] = []
        if (additionalFilters) {
            dimensionValues.push(
                ...ConditionClauseService.getAllDimensionValuesFromFilter(additionalFilters, requiredDimension),
            )
        }

        if (dimensionValues?.length > 0) {
            return {
                ...acc,
                [requiredDimension]: dimensionValues[0],
            }
        } else {
            return acc
        }
    }, {})
}

const flattenValues = (values: Values): DataRowDTO => {
    const flattenedValues: DataRowDTO = {}

    Object.keys(values).forEach((dimension) => {
        if (values[dimension][ColumnFieldType.VALUE] !== undefined) {
            flattenedValues[`${dimension}.${ColumnFieldType.VALUE}`] = values[dimension][ColumnFieldType.VALUE]
        }
        if (values[dimension][ColumnFieldType.NAME] !== undefined) {
            flattenedValues[`${dimension}.${ColumnFieldType.NAME}`] = values[dimension][ColumnFieldType.NAME]
        }
    })

    log.debug("-- FORM: flattenedValues", flattenedValues)
    return flattenedValues
}
