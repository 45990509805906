import { SelectedNodeUserSettingsValueDTO, UserSettingsDTO, UserSettingsResponseDTO } from "generated/models"
import { UserSettingsApiControllerApi } from "generated/ui_user_apis"
import { ServiceConfigs } from "shared/ServiceConfigs"
import { CookieUtil } from "shared/util/CookieUtil"

const userSettingsApiControllerApi = new UserSettingsApiControllerApi(ServiceConfigs.uiUserServiceConfig)

const loadSelectedNodeUserSetting = (): Promise<SelectedNodeUserSettingsValueDTO> =>
    loadAllUserSettings().then((response: UserSettingsResponseDTO) => {
        const selectedNodeSetting = response.settings.find((row) => row.id === "selectedNode")

        return selectedNodeSetting
            ? (selectedNodeSetting.value as SelectedNodeUserSettingsValueDTO)
            : { type: "SelectedNodeUserSettingsValueDTO", advertiserId: null }
    })

const updateUserSettingDTO = (setting: UserSettingsDTO): Promise<void> => {
    return userSettingsApiControllerApi.settingsUpdatePut(
        { userSettingsDTO: setting },
        {
            // copy the XSRF token from the cookie to the header
            headers: {
                "X-XSRF-TOKEN": CookieUtil.getCookie("XSRF-TOKEN"),
                "Content-Type": "application/json",
            },
        },
    )
}

const deleteUserSettingById = (id: string): Promise<void> => {
    return userSettingsApiControllerApi.settingsDeleteIdDelete({ id: id })
}

const loadAllUserSettings = (): Promise<UserSettingsResponseDTO> => {
    return userSettingsApiControllerApi.settingsLoaddataGet()
}

export const UserSettingsService = {
    loadSelectedNodeUserSetting: loadSelectedNodeUserSetting,
    loadAllUserSettings: loadAllUserSettings,
    updateUserSettingDTO: updateUserSettingDTO,
    deleteUserSettingById: deleteUserSettingById,
}
