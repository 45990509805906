import * as api from "shared/service"
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from "axios"
import { v4 as uuid } from "uuid"

import {
    DimensionValueListDTO,
    FilterConfigDTO,
    GridElementConfigDTO,
    LoadResponseDTOReportingDataSetDTO,
    QuerySettingsDTO,
} from "generated/models"
import { ColumnResponseDTO, ConditionClauseDTO, PageableDTO } from "generated/models"
import store from "shared/redux/store"
import { log } from "shared/util/log"
import { DataSettingsState } from "domain/legacy/widget/generic/GenericDataGridWidget"
import { SELECTED_FIELD } from "domain/datagrid/component/DataGrid"
import { getColumnNamesForShow } from "domain/legacy/widget/generic/GridUtil"
import UrlService from "shared/service/url.service"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import { postCancellableData } from "shared/service"
import UrlUtil from "shared/util/UrlUtil"
import { DataRowDTO, GridDataRowDTO, MappedGridConfig } from "domain/types"

const getCancelTokenSource = (): CancelTokenSource => axios.CancelToken.source()

const fetchRows = async (
    querySettings: QuerySettingsDTO,
    baseUrl: string,
    api: string,
    cancelToken?: CancelTokenSource,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    querySettings.appContext = store.getState().appContext.appContext
    try {
        const result: LoadResponseDTOReportingDataSetDTO =
            await postCancellableData<LoadResponseDTOReportingDataSetDTO>(baseUrl, api, querySettings, {
                cancelToken: cancelToken?.token,
            })

        result.dataSet.rows.forEach((entry, index) => {
            entry[SELECTED_FIELD] = { value: false }

            return entry
        })

        return result
    } catch (error) {
        if (axios.isCancel(error) || error.message === "Cancel") {
            log.debug("cancelled request: ", error.message)
        }
        return error
    }
}

const loadData = (
    dataSettingsState: DataSettingsState,
    filter: ConditionClauseDTO,
    cancelToken?: CancelTokenSource,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const { settings, columns, pagination, sortSettings, searchTerm, timespanSettings } = dataSettingsState

    const { paths, embedded } = settings

    const columnNames = getColumnNamesForShow(columns)

    const paginationSettings: PageableDTO = {
        page: pagination.page,
        pageSize: embedded ? 5 : pagination.pageSize,
    }

    const querySettings: QuerySettingsDTO = {
        columnNames,
        filter,
        paginationSettings: paginationSettings,
        sortSettings: sortSettings,
        timespanSettings: timespanSettings,
        queryIdentifier: { value: uuid() },
    }

    return DataGridService.fetchRows(
        querySettings,
        UrlUtil.joinUrl(UrlService.getGatewayUrl(), paths.serviceContextPath, paths.base),
        paths.data,
        cancelToken,
    )
}

const fetchFilterConfig = async (path: string, baseURL?: string, cancelToken?: CancelToken): Promise<any> => {
    return await fetch<FilterConfigDTO>(path, { baseURL, cancelToken })
}

const fetchFilterEntries = async (
    querySettings: QuerySettingsDTO,
    path: string,
    cancelToken?: CancelToken,
): Promise<DimensionValueListDTO> => {
    querySettings.appContext = store.getState().appContext.appContext
    return await post<DimensionValueListDTO>(querySettings, path, { cancelToken })
}

/**
 * map grid config to something antd can actually create a table from
 *
 * @param config
 */
const mapGridConfig = (config: GridElementConfigDTO): MappedGridConfig => {
    return {
        ...config.gridConfig,
        // filterConfigs: config.gridConfig.filterConfigs,
        paths: config.paths,
    }
}

/**
 * Converts GridDataRowDTO to table structure
 *
 * @param row
 */
const convertRowToTableStructure = (row: GridDataRowDTO): DataRowDTO => {
    const result: DataRowDTO = {}
    Object.keys(row).forEach((dimensionIdentifier) => {
        const columnData: ColumnResponseDTO = row[dimensionIdentifier]
        result[DimensionService.getValueColumn(asDataColumnIdentifier(dimensionIdentifier))] = columnData
        result[DimensionService.getNameColumn(asDataColumnIdentifier(dimensionIdentifier))] = columnData
    })

    return result
}

const DataGridService = {
    fetchRows,
    fetchFilterConfig,
    fetchFilterEntries,
    getCancelTokenSource,
    mapGridConfig,
    loadData: loadData,
    convertRowToTableStructure: convertRowToTableStructure,
}
export default DataGridService

const fetch = <T>(path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.get(path, config)
}

const post = <T>(data: any, path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.post(path, data, config)
}
